<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicles">Fahrzeuge</router-link></li>
              <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">{{ vehicle.license_plate }}</router-link></li>
              <li>Reparaturen</li>
            </ul>
            <div class="header-actions">
              <p><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs/new'" class="button button-orange button-small">Reparatur hinzufügen ›</router-link></p>
            </div>
          </header>

          <ul class="nav nav-tabs cf">
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id">Stammdaten</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/reservations'">Reservierungen</router-link></li>
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/damages'">Schäden</router-link></li>
            <li class="active"><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/repairs'">Reparaturen</router-link></li>
            <!--<li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/insurance'">Versicherung</router-link></li>-->
            <li><router-link :to="'/admin/vehicles/'+this.$route.params.id+'/documents'">Dokumente</router-link></li>
          </ul>

          <div class="admin-body">
            <div v-if="vehicle_repairs.length == 0">
              <p>Noch keine Reparaturen.</p>
            </div>
            <div v-else>

              <table class="table">
                <thead>
                  <tr>
                    <th width="20%">Beginn</th>
                    <th width="20%">Ende</th>
                    <th width="25%">Beschreibung</th>
                    <th width="25%">Bild</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="repair in vehicle_repairs" v-bind:key="repair.id">
                    <td>{{ repair.start }}</td>
                    <td>{{ repair.end }}</td>
                    <td>{{ repair.description }}</td>
                    <td><img :src="repair.image" height="30"></td>
                    <td style="text-align:right">
                      <router-link :to="'/admin/vehicles/'+vehicle.id+'/repairs/'+repair.id" class="material-icons">edit</router-link>
                      <a @click="delete_repair(repair)" style="margin-left:5px;cursor:pointer"><span class="material-icons" style="margin-right:0">delete</span></a>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../../components/admin_nav'
import moment from 'moment';

export default {
  name: 'admin_vehicle_repairs',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle: {},
      vehicle_repairs: []
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle = response.data.vehicle;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.$route.params.id+'/repairs', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        response.data.vehicle_repairs.forEach(value => {
          var val = {
            id: value.id,
            start: moment(value.start).format("DD.MM.YYYY hh:mm"),
            end: moment(value.end).format("DD.MM.YYYY hh:mm"),
            description: value.description,
            image: value.image,
            created_at: value.created_at,
            updated_at: value.updated_at
          }
          this.vehicle_repairs.push(val)
        });
      })
      .catch(error => {
        console.log(error);
      })

    },
    delete_repair(repair) {
      if(confirm("Wirklich löschen?")) {
        axios.delete(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles/'+this.vehicle.id+'/repairs/'+repair.id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          console.log(response);
          this.vehicle_repairs = [];
          this.$notify({
            group: 'alert',
            type: 'success',
            title: 'Erfolgreich gelöscht.'
          });
          this.get_data();
        })
      }
    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
